<script>
import { PREPRINTS } from '@/config'
import { fetchSearchResults, fetchYearlyCounts } from '@/api'
import convertNum from '@/helpers/number'
import { Chart } from 'epmc-patterns/components/v2'
import StaticPageLayout from '@/templates/static/StaticPageLayout'

const startYear = 2020

const getMonthRanges = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const monthRanges = []
  for (let i = 0; i <= currentYear - startYear; i++) {
    const year = startYear + i
    // covid pprs began since july 2020
    const startMonth = year === startYear ? 6 : 0
    const endMonth = year === currentYear ? currentDate.getMonth() : 11
    for (let month = startMonth; month <= endMonth; month++) {
      const startDate = 1
      const endDate =
        year === currentYear && month === endMonth
          ? currentDate.getDate()
          : new Date(year, month + 1, 0).getDate()
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      monthRanges.push([
        year +
          '-' +
          ('0' + (month + 1)).slice(-2) +
          '-' +
          ('0' + startDate).slice(-2),
        year +
          '-' +
          ('0' + (month + 1)).slice(-2) +
          '-' +
          ('0' + endDate).slice(-2),
        monthNames[month] + ' ' + year,
      ])
    }
  }
  return monthRanges
}

export default {
  metaInfo() {
    return {
      title: 'Preprints - About - Europe PMC',
    }
  },
  components: { Chart, StaticPageLayout },
  data() {
    return {
      title: 'Preprints in Europe PMC',
      sections: [
        {
          id: 'about-including-preprints',
          heading: 'h2',
          title: 'Introduction',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'about-preprints',
          heading: 'h2',
          title: 'What is a preprint?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'questions-about-preprints',
          heading: 'h2',
          title: 'Why is my preprint included?',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'funder-preprints-indexing',
          heading: 'h2',
          title: 'Europe PMC full text preprint initiatives',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'preprint-display',
          heading: 'h2',
          title: 'How preprints are displayed',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'preprint-servers',
          heading: 'h2',
          title: 'Preprint servers indexed',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'preprint-criteria',
          heading: 'h2',
          title: 'Criteria for preprint servers',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'preprint-metadata',
          heading: 'h2',
          title: 'Metadata required',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
      pprCount: '',
      pprQuery: { query: 'SRC:PPR' },
      chart: {
        type: 'bar',
        labels: [],
        datasets: [],
        options: {
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Date full text first indexed',
                  fontSize: 14,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: 'No. of preprints',
                  fontSize: 14,
                },
              },
            ],
          },
        },
        update: false,
      },
      PREPRINTS,
    }
  },
  created() {
    fetchSearchResults(this.pprQuery).then((response) => {
      this.pprCount = convertNum(response.hitCount)
    })

    const monthRanges = getMonthRanges()
    const getFetches = (hasFT) =>
      monthRanges.map((range) =>
        fetchYearlyCounts(
          'FT_ID:PPR*' +
            ' AND HAS_FT:' +
            (hasFT ? 'Y' : 'N') +
            ' AND FT_CDATE:[' +
            range[0] +
            ' TO ' +
            range[1] +
            ']'
        )
      )
    const promises = getFetches(false).concat(getFetches(true))
    Promise.all(promises).then((response) => {
      const { chart } = this
      chart.labels = monthRanges.map((range) => range[2])
      chart.datasets.push(
        {
          label: 'Full text COVID-19 preprints indexed',
          data: response
            .slice(0, response.length / 2)
            .map((yc) => (yc.length ? yc[0].count : 0)),
        },
        {
          label: 'Full text COVID-19 preprints available to read',
          data: response
            .slice(response.length / 2, response.length)
            .map((yc) => (yc.length ? yc[0].count : 0)),
        }
      )
      chart.update = response.filter((res) => res.length)
    })
  },
}
</script>
<template>
  <static-page-layout id="preprints-page" :title="title" :sections="sections">
    <template slot-scope="{ section }">
      <template v-if="section.id === 'about-including-preprints'">
        <p>
          The Covid-19 pandemic of 2020 has highlighted the need for rapid
          communication of research results to healthcare professionals and
          governments. Preprints are one way researchers can remove barriers to
          open science and share their findings quickly.
        </p>
        <p>
          Since 2018, Europe PMC has made
          <a :href="'/search?query=' + pprQuery.query"
            >{{ pprCount }} preprints</a
          >, and versions when available, searchable alongside journal published
          articles. If a preprint has been published in a journal indexed in
          Europe PMC, the preprint and published article are linked. Indexing
          preprints alongside journal published articles makes them more
          discoverable.
        </p>
        <p><b>Preprints in Europe PMC</b></p>
        <iframe
          src="//literature-services.gitdocs.ebi.ac.uk/public-projects/europe-pmc-reports/about_page_1"
          title="The number of full text preprints ingested by Europe PMC"
        >
        </iframe>
        <p>
          Similar to other articles in Europe PMC, preprints are linked to
          <a href="/article/PPR/PPR158775#data">data behind the paper</a>, can
          be claimed to an <a href="/article/PPR/PPR161858">ORCID</a>, included
          in <a href="/article/PPR/PPR30268#impact">citation networks</a>, and
          linked to
          <a href="/article/PPR/PPR701206#reviews"
            >platforms that comment on or peer review preprints</a
          >.
        </p>
      </template>
      <template v-else-if="section.id === 'about-preprints'">
        <p>
          A preprint is a complete scientific manuscript that an author uploads
          on a public server for free viewing. Initially it is posted without
          peer review, but may acquire feedback or reviews as a preprint, and
          may eventually be published in a peer-reviewed journal. The posting of
          preprints on public servers allows almost immediate dissemination and
          scientific feedback early in the ‘publication’ process. Europe PMC has
          contributed to preprint community standards development and has
          hands-on expertise in publication workflows that include versions
          across multiple sources with diverse approaches.
        </p>
        <p>
          If you are new to preprints, we recommend you visit
          <a href="//asapbio.org/preprint-info/preprint-faq"
            >ASAPbio’s FAQ page</a
          >
          for answers to general questions on the use of preprints in scientific
          publishing.
        </p>
      </template>
      <template v-else-if="section.id === 'questions-about-preprints'">
        <p>
          Europe PMC indexes preprints from
          <a href="#preprint-servers">servers with publicly available content</a
          >. Europe PMC has permission to download the PDF and supplementary
          files, and display the full text. If you have questions about how your
          preprint came to be included in Europe PMC, please see
          <a href="https://plus.europepmc.org/user-guide/preprintfaqs"
            >Preprint FAQs</a
          >.
        </p>
      </template>
      <template v-else-if="section.id === 'funder-preprints-indexing'">
        <p>
          Europe PMC has two full text preprint initiatives, preprints related
          to Covid-19 and preprints funded by Europe PMC funders. For
          researchers, this means a greater number of search results can be
          returned when searching for content on Europe PMC. If a preprint has
          an open access licence, the full text will also be available for
          programmatic text mining enabling deeper analysis.
        </p>
        <p><b>COVID-19 full text preprints indexed in Europe PMC</b></p>
        <p>
          In July 2020, Europe PMC began indexing the full text of COVID-19
          related preprints to make them searchable, alongside peer reviewed
          articles.
        </p>
        <chart
          :type="chart.type"
          :labels="chart.labels"
          :datasets="chart.datasets"
          :options="chart.options"
          :update="chart.update"
          aria-label="COVID-19 preprints full text indexed in Europe PMC"
          role="img"
        />
        <p>
          The COVID-19 preprints initiative was supported by a joint award from
          Wellcome, UK Medical Research Council (MRC), Swiss National Science
          Foundation (SNSF), and endorsed by the Chief Scientist of the World
          Health Organisation (WHO).
        </p>
        <blockquote>
          <em
            >As the Chief Scientist of WHO I welcome the huge increase in the
            use of pre-prints by researchers to rapidly share the emerging
            evidence from the many studies on Covid-19. However, these are
            published as .pdf documents and I recognise that the information
            they contain could be more rapidly searched and linkages made
            between the results and data they contain if they were converted to
            the standard publishing language XML. I therefore support this
            initiative by Europe PMC to take on this task."</em
          ><br />
        </blockquote>
        <p class="author">
          —Dr. Soumya Swaminathan<br />
          &nbsp;&nbsp;&nbsp;&nbsp;Chief Scientist, WHO<br />
          &nbsp;&nbsp;&nbsp;&nbsp;4 May 2020
        </p>
        <p>
          This initiative was extended with a follow up award from Wellcome, UK
          and ended on 31 October 2023.
        </p>
        <!--End date to be added  -->
        <p>
          <b>Europe PMC funder full text preprints indexed in Europe PMC</b>
        </p>
        <p>
          From April 2022 Europe PMC began indexing the full text of preprints
          supported by
          <a href="/Funders">Europe PMC funders</a> with Creative Commons
          licences. Similar to the COVID-19 preprints initiative, the full text
          is searchable and will also be available for programmatic text mining.
        </p>
      </template>
      <template v-else-if="section.id === 'preprint-display'">
        <p>
          Preprints in Europe PMC are clearly identifiable by the green preprint
          label and an orange notification box which provides a warning that the
          article may not have been peer reviewed. If a published version of the
          article is available a link is provided to it. Different versions of
          the preprint are linked from the preprint version history. All
          preprints in Europe PMC are assigned a unique PPR identifier.
        </p>
        <p><b>Preprints appearing in search results</b></p>
        <div>
          <img
            src="@/assets/Preprint_search_results.png"
            alt="Preprint search results"
          />
        </div>
        <p>
          Note that a preprints search filter is available in the left hand
          column. The preprint version is indicated in the green label. Only the
          latest version of a preprint is displayed in search results, but early
          versions can be found on the preprint page.
        </p>
        <p><b>Preprint displayed within Europe PMC</b></p>
        <div>
          <img
            src="@/assets/Preprint_versions.png"
            alt="Preprint search results"
          />
        </div>
        <p>
          Note the preprint version indicated in the green label, an orange
          preprint notification, and the full version history.
        </p>
      </template>
      <template v-else-if="section.id === 'preprint-servers'">
        <p>
          Currently, Europe PMC indexes metadata/abstracts from the following
          preprint servers:
        </p>
        <table class="dark">
          <tr>
            <th>Preprint server</th>
            <th>Metadata/abstracts indexed</th>
            <th>COVID-19 full text indexed</th>
            <th>Funder full text indexed</th>
          </tr>
          <tr v-for="server in PREPRINTS" :key="server.name">
            <td>{{ server.name }}</td>
            <td class="center">
              <i v-if="server.abstractIndexed" class="fas fa-check" />
            </td>
            <td class="center">
              <i v-if="server.fulltextIndexed" class="fas fa-check" />
            </td>
            <td class="center">
              <p v-if="server.funderfulltextIndexed === 'CC - licensed only'">
                <b>CC - licensed only</b>
              </p>
              <i
                v-if="server.funderfulltextIndexed === true"
                class="fas fa-check"
              />
            </td>
          </tr>
        </table>
      </template>
      <template v-else-if="section.id === 'preprint-criteria'">
        <p>
          Europe PMC considers various criteria when deciding whether to index
          content from a preprint server. These are as follows:
        </p>
        <ul>
          <li>
            <b>Scope</b>: the preprint server should contain a significant
            proportion of content in life science or interdisciplinary subjects
          </li>
          <li>
            <b>Volume</b>: the preprint server should have at least 30 preprints
            available
          </li>
          <li>
            <b>Screening</b>: the preprint server should have a screening
            procedure that is described in a public statement
          </li>
          <li>
            <b>Access to metadata</b>: preprint metadata should be available in
            a machine-readable format e.g. via an API
          </li>
          <li>
            <b>Access to full text</b>: the full text of all preprints should be
            visible on the preprint server with no barrier to access
          </li>
          <li>
            <b>Publication ethics</b>: the preprint server should have a public
            statement on its policies on plagiarism, misconduct, and competing
            interests
          </li>
        </ul>
        <p>
          Europe PMC reserves the right to not index content from a preprint
          server should any of these criteria not be met.
        </p>
      </template>
      <template v-else-if="section.id === 'preprint-metadata'">
        <p>
          Europe PMC requires the following metadata in a machine readable
          format:
        </p>
        <ul>
          <li>Preprint identifier (currently a Crossref DOI is required)</li>
          <li>Preprint title</li>
          <li>Author names</li>
          <li>Abstract</li>
          <li>Publication date</li>
        </ul>
        <p>Desirable metadata:</p>
        <ul>
          <li>Author affiliations</li>
          <li>Links to peer-reviewed versions</li>
          <li>Licencing</li>
          <li>Funding</li>
          <li>Version information</li>
          <li>Withdrawal/removal status</li>
        </ul>
        <p>
          If you are a preprint server or open research platform and interested
          in getting your preprints indexed by Europe PMC, please get in touch
          by contacting
          <a href="mailto:helpdesk@europepmc.org">helpdesk@europepmc.org</a>.
        </p>
      </template>
    </template>
  </static-page-layout>
</template>
<style scoped lang="scss">
#preprints-page {
  .author {
    padding-left: $base-unit * 9;
  }
  iframe {
    width: 100%;
    height: $base-unit * 128;
  }
  .center {
    text-align: center;
  }
}
</style>
